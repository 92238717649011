import {
  createContext, ReactNode, useCallback, useMemo, useState,
} from 'react';
import { CURRENCY, ECurrency } from '../constants';

export interface AppContextProps {
  upsertUser: (user?: User) => void,
  user: User | undefined,
  updateCurrency: (val: ECurrency) => void,
  currency: ECurrency,
  categories: Category[],
  updateCategories: (categories: Category[]) => void
}

export const AppContext = createContext<AppContextProps>({
  upsertUser: () => null,
  user: undefined,
  updateCurrency: () => null,
  currency: CURRENCY.UAH.val,
  categories: [],
  updateCategories: () => null,
});

export const AppContextProvider = ({ children }: { children: ReactNode }) => {
  const [currency, setCurrency] = useState<ECurrency>(CURRENCY.UAH.val);
  const [user, setUser] = useState<User>();
  const upsertUser = useCallback((u?: User) => {
    setUser(u);
    if (u) setCurrency(u?.currency);
  }, []);

  const updateCurrency = useCallback((val: ECurrency) => {
    setCurrency(val);
  }, []);

  const [categories, setCategories] = useState([]);

  const updateCategories = useCallback((c: Category[] = []) => {
    setCategories(c);
  }, []);

  const values = useMemo(() => ({
    upsertUser,
    user,
    updateCurrency,
    currency,
    categories,
    updateCategories,
  }), [categories, currency, updateCategories, updateCurrency, upsertUser, user]);

  return (
    <AppContext.Provider value={values}>
      {children}
    </AppContext.Provider>
  );
};
